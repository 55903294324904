// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Unit functions\n */\n.PmG6owJkbidv_uhGjhEa {\n  background: var(--theme-color-secondary);\n  box-shadow: 0 0.5rem 1.5rem 0 rgba(66, 68, 90, 0.25);\n  opacity: 0;\n  pointer-events: none;\n  position: relative;\n  transition: opacity 0.25s;\n  z-index: 9999;\n}\n\n.rwfYjbmWPwBOqtdqckCc {\n  opacity: 1;\n  pointer-events: all;\n}", "",{"version":3,"sources":["webpack://./src/scss/_unit.scss","webpack://./src/components/Popover/Popover.module.scss"],"names":[],"mappings":"AAAA;;EAAA;ACEA;EACE,wCAAA;EACA,oDAAA;EACA,UAAA;EACA,oBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;AAEF;;AACA;EACE,UAAA;EACA,mBAAA;AAEF","sourcesContent":["/**\n * Unit functions\n */\n\n@use 'sass:math';\n@use 'sass:string';\n@use 'sass:list';\n\n$rem-base: 16 !default;\n\n@function is-unitless($value) {\n  @return 0 == string.length(unit($value));\n}\n\n@function rem-calc-list($values) {\n  $result: ();\n\n  @each $value in $values {\n    $result: list.append($result, rem-calc($value));\n  }\n\n  @return $result;\n}\n\n@function rem-calc($size) {\n  @if type-of($size) == 'list' {\n    @return rem-calc-list($size);\n  }\n\n  @return math.div($size, $rem-base) * 1rem;\n}\n","@use 'scss/unit' as u;\n\n.popover {\n  background: var(--theme-color-secondary);\n  box-shadow: 0 u.rem-calc(8) u.rem-calc(24) 0 rgba(66, 68, 90, 0.25);\n  opacity: 0;\n  pointer-events: none;\n  position: relative;\n  transition: opacity 0.25s;\n  z-index: 9999;\n}\n\n.isOpen {\n  opacity: 1;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "PmG6owJkbidv_uhGjhEa",
	"isOpen": "rwfYjbmWPwBOqtdqckCc"
};
export default ___CSS_LOADER_EXPORT___;
