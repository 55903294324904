// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Unit functions\n */\n.GOHl3FLwJOckJ0xXXK33 {\n  opacity: 0;\n  pointer-events: none;\n  position: absolute;\n}\n\n.Av7w9OY1RoEmlDwWuBJg {\n  color: var(--theme-color-secondary);\n  column-gap: 5rem;\n  display: inline-flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  width: 100%;\n}\n\n.J51aiWOtbD19N773mtjW {\n  display: flex;\n  flex: auto;\n  gap: 1.25rem;\n  justify-content: space-evenly;\n}", "",{"version":3,"sources":["webpack://./src/scss/_unit.scss","webpack://./src/components/Title/Title.module.scss"],"names":[],"mappings":"AAAA;;EAAA;ACEA;EACE,UAAA;EACA,oBAAA;EACA,kBAAA;AAEF;;AACA;EACE,mCAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;EACA,6BAAA;EACA,WAAA;AAEF;;AACA;EACE,aAAA;EACA,UAAA;EACA,YAAA;EACA,6BAAA;AAEF","sourcesContent":["/**\n * Unit functions\n */\n\n@use 'sass:math';\n@use 'sass:string';\n@use 'sass:list';\n\n$rem-base: 16 !default;\n\n@function is-unitless($value) {\n  @return 0 == string.length(unit($value));\n}\n\n@function rem-calc-list($values) {\n  $result: ();\n\n  @each $value in $values {\n    $result: list.append($result, rem-calc($value));\n  }\n\n  @return $result;\n}\n\n@function rem-calc($size) {\n  @if type-of($size) == 'list' {\n    @return rem-calc-list($size);\n  }\n\n  @return math.div($size, $rem-base) * 1rem;\n}\n","@use 'scss/unit' as u;\n\n.title {\n  opacity: 0;\n  pointer-events: none;\n  position: absolute;\n}\n\n.splitTitle {\n  color: var(--theme-color-secondary);\n  column-gap: u.rem-calc(80);\n  display: inline-flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  width: 100%;\n}\n\n.word {\n  display: flex;\n  flex: auto;\n  gap: u.rem-calc(20);\n  justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "GOHl3FLwJOckJ0xXXK33",
	"splitTitle": "Av7w9OY1RoEmlDwWuBJg",
	"word": "J51aiWOtbD19N773mtjW"
};
export default ___CSS_LOADER_EXPORT___;
