// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZJeiVNoZpiKBNvfoBaWi {\n  min-height: 100vh;\n  position: relative;\n}\n\n.L9jmSz3w4ZvQIl9z3Uu8 {\n  position: relative;\n  z-index: 2;\n}\n\n.giJ6ctCppUoSc85qOjoK {\n  position: absolute;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/sections/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;AACF","sourcesContent":[".wrapper {\n  min-height: 100vh;\n  position: relative;\n}\n\n.title {\n  position: relative;\n  z-index: 2;\n}\n\n.hero {\n  position: absolute;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ZJeiVNoZpiKBNvfoBaWi",
	"title": "L9jmSz3w4ZvQIl9z3Uu8",
	"hero": "giJ6ctCppUoSc85qOjoK"
};
export default ___CSS_LOADER_EXPORT___;
