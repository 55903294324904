const exhibitions = [
	{
		year: 2015,
		data: [
			{
				dogName: 'Piccolo Perla',
				date: '11-12.07.2015',
				location: 'Warszawa',
				exhibitionName: 'Międzynarodowa Wystawa Psów Rasowych',
				arbiterName: 'Bogusława Szydłowicz-Polańczyk',
				grade: 'Wybitnie Obiecująca',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '04.10.2015',
				location: 'Rybnik',
				exhibitionName: 'XXXII Krajowa Wystawa Psów Rasowych',
				arbiterName: 'Zdenka Jilkova',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: 'Piccolo Perla',
				date: '18.10.2015',
				location: 'Jasło',
				exhibitionName: 'XXIV Podkarpacka Wystawa Psów Rasowych',
				arbiterName: 'Grzegorz Robak',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: 'Piccolo Perla',
				date: '07.11.2015',
				location: 'Poznań',
				exhibitionName: 'Międzynarodowa Wystawa Psów Rasowych',
				arbiterName: 'Anatoli Zhuk',
				grade: 'Doskonała',
				place: 4,
			},
			{
				dogName: 'Piccolo Perla',
				date: '08.11.2015',
				location: 'Poznań',
				exhibitionName: 'Międzynarodowa Wystawa Psów Rasowych',
				arbiterName: 'Krystyna Opara',
				grade: 'Bardzo Dobra',
				place: 4,
			},
		],
	},
	{
		year: 2016,
		data: [
			{
				dogName: 'Piccolo Perla',
				date: '01.05.2016',
				location: 'Dobre Miasto',
				exhibitionName: 'XIX Krajowa Wystawa Psów Rasowych',
				arbiterName: 'Zofia Konderla',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: 'Piccolo Perla',
				date: '29.05.2016',
				location: 'Gostynin',
				exhibitionName: '38. Mazowiecka Wystawa Psów Rasowych',
				arbiterName: 'Bogusława Szydłowicz-Polańczyk',
				grade: 'Doskonała',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '10.06.2016',
				location: 'Nitra - Słowacja',
				exhibitionName: 'Celostatna Vystava Psov',
				arbiterName: 'Suzana Verstovsek Matakovic',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: 'Piccolo Perla',
				date: '11.06.2016',
				location: 'Nitra - Słowacja',
				exhibitionName: 'Cacib - Grand Prix Slovakia',
				arbiterName: 'Nikola Smolić',
				grade: 'Bardzo Dobra',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '12.06.2016',
				location: 'Nitra - Słowacja',
				exhibitionName: 'Cacib - Derby Winner Show',
				arbiterName: 'Adam Ostrowski',
				grade: 'Doskonała',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '03.07.2016',
				location: 'Olsztyn-Szczytno',
				exhibitionName:
					'XLIII Warmińsko-Mazurska Wystawa Psów Rasowych',
				arbiterName: '',
				grade: 'Bardzo Dobra',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '09-10.07.2016',
				location: 'Warszawa',
				exhibitionName: 'Międzynarodowa Wystawa Psów',
				arbiterName: 'Birgitta Svarstad',
				grade: 'Bardzo Dobra',
				place: 3,
			},
		],
	},
	{
		year: 2017,
		data: [
			{
				dogName: 'Piccolo Perla',
				date: '06.05.2017',
				location: 'Mińsk Maz. Warszawa',
				exhibitionName: 'Krajowa Wystawa Psów Rasowych 9. Grupy FCI',
				arbiterName: 'Aleksander Krstic',
				grade: 'Doskonała',
				place: 1,
			},
			{
				dogName: 'Piccolo Perla',
				date: '21.05.2017',
				location: 'Kamionka',
				exhibitionName:
					'X Międzynarodowa Jubileuszowa Wystawa Psów Rasowych',
				arbiterName: 'Małgorzata Supronowicz',
				grade: 'Doskonała',
				place: 1,
			},
		],
	},
	{
		year: 2018,
		data: [
			{
				dogName: "Bacio D'Amore",
				date: '30.08.2020',
				location: 'Kleszczów',
				exhibitionName: 'Krajowa Wystawa Psów Rasowych 9 Grupy FCI',
				arbiterName: 'Janusz Opara',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: "Bacio D'Amore",
				date: '20.09.2020',
				location: 'Wawrzykowizna',
				exhibitionName: 'Krajowa Wystawa Psów Rasowych',
				arbiterName: 'Krzysztof Kania',
				grade: 'Doskonała',
				place: 1,
			},
			{
				dogName: "Bacio D'Amore",
				date: '26.09.2020',
				location: 'Leszno',
				exhibitionName: 'Międzynarodowa Wystawa Psów Rasowych',
				arbiterName: 'Andrzej Kaźmierski',
				grade: 'Doskonała',
				place: 2,
			},
			{
				dogName: "Bacio D'Amore",
				date: '03/04.10.2020',
				location: 'Nowy Dwór Mazowiecki',
				exhibitionName: '45 Krajowa Wystawa Psów Rasowych',
				arbiterName: 'Piotr Król',
				grade: 'Doskonała',
				place: 1,
			},
		],
	},
];

export default exhibitions;
